<template>
  <modal
    name="evi_modal"
    :adaptive="true"
    :max-width="1100"
    height="700px"
    width="75%"
    @before-open="beforeOpen"
    @before-close="beforeClose"
  >
    <div class="chatbot-wrapper">
      <section class="chatbot-header d-flex justify-content-center align-items-center">
        <div class="d-flex justify-content-center">
          <div class="d-flex align-items-center icon" v-html="AiMagicIcon" />
          <span>Evi</span>
        </div>
        <div class="close-icon d-flex" @click="handleClose">
          <b-icon-x></b-icon-x>
        </div>
      </section>

      <div class="chatbot-content-wrapper d-flex">
        <div class="chat-content d-flex flex-column">
          <ChatBox
            v-if="account"
            :account="account"
            mode="external"
            :welcome-message="welcomeMessage(account?.name)"
            :default-questions="defaultQuestionOptions(account?.name)"
            @stream-completed="handleStreamCompleted"
          ></ChatBox>
        </div>

        <EviAssetPreviewer :account="account" :loading="loading" :assets="previewAssets" />
      </div>
    </div>
  </modal>
</template>

<script lang="ts">
import axios from 'axios'
import aiMagicIcon from '@images/icons/ai-magic.svg?raw'
import ChatBox from '@app/pages/content_assets/ChatBox.vue'
import EviAssetPreviewer from './EviAssetPreviewer.vue'

export default {
  components: { EviAssetPreviewer, ChatBox },
  data() {
    return {
      account: null,
      loadingPreviews: false,
      previewableAssets: [],
    }
  },
  computed: {
    AiMagicIcon() {
      return aiMagicIcon
    },
    previewAssets() {
      return this.previewableAssets
    },
    loading() {
      return this.loadingPreviews
    },
  },
  methods: {
    defaultQuestionOptions(companyName) {
      const questions = [
        `What is the ROI of implementing ${companyName}`,
        'Give me testimonials from companies in the tech sector',
        `What are the long-term benefits of using ${companyName}`,
      ]
      return questions
    },
    handleStreamCompleted(message) {
      this.findUeviLinks(message)
    },
    async findUeviLinks(message) {
      // TODO: get rid of that
      const that = this

      // This finds all the links in the chat response
      // containing https://uevi.co/
      const links = message.match(/https:\/\/uevi.co\/\w+/g)

      if (links && links.length > 0) {
        this.previewableAssets = []
        this.loadingPreviews = true
        const ids = links.map((link) => {
          const items = link.split('/')
          return items[items.length - 1]
        })

        axios
          .all(ids.map((id) => axios.get(`/api/content_assets/${id}.json`)))
          .then(
            axios.spread(function (...res) {
              // all requests are now complete
              res.forEach((r) => {
                that.previewableAssets.push(r.data)
              })
            }),
          )
          .finally(() => {
            that.loadingPreviews = false
          })
      }
    },
    handleClose() {
      this.$modal.hide('evi_modal')
    },
    beforeOpen(account) {
      this.account = account.params
    },
    beforeClose() {
      this.previewableAssets = []
    },
    welcomeMessage(company) {
      return `Hi 👋, I’m Evi. Ask me anything about ${company}’s user evidence. Here are a few examples of what you can ask:`
    },
  },
}
</script>

<style lang="sass" scoped>

.preview-content-wrapper
  overflow: scroll
  -ms-overflow-style: none
  scrollbar-width: none
  height: 647px
  background: #f8f8f8
  width: 50%
  border-left: 1px solid #e0e0e0
  border-bottom-right-radius: 1rem
  .preview
    height: 100%
    font-weight: $headings-font-weight
    font-size: 0.7rem
  .logo-wrapper
    border-radius: 50%
    width: 70px
    height: 70px
    background: white
  .loading-preview
    height: 100%
    font-weight: $headings-font-weight
    font-size: 0.7rem

.chatbot-wrapper
  border: 1px solid #e0e0e0
  border-radius: 1rem

.chatbot-content-wrapper
  border-top: 1px solid #e0e0e0

.chatbot-header
  padding: 0.5rem
  text-align: center
  font-size: 1rem
  font-weight: $headings-font-weight
  ::v-deep svg
    width: 30px
    height: 30px
  .close-icon
    cursor: pointer
    position: absolute
    margin-left: 95%
    svg
      color: black

.chat-content
  width: 50%
</style>

<template>
  <div v-if="mode == 'internal'">
    <div class="chat-box chat-box--internal d-flex flex-column">
      <div class="top-controls d-flex p-2 justify-content-center">
        <p class="ml-2">{{ account.name }} Testimonial Bot</p>
      </div>

      <div class="d-flex justify-content-center mt-1">
        <span class="chat-box-time">{{ time }}</span>
      </div>

      <div ref="chat_box_content" class="chat-box-content overflow-auto p-2 flex-grow-1">
        <div class="chat-box-messages d-flex flex-column">
          <div class="chat-box-message mt-2">
            <BotMessage
              :bots-name="botsName"
              message="Ask me anything about your testimonials!"
            ></BotMessage>
          </div>

          <div v-for="msg in messages" :key="msg.id" class="chat-box-message">
            <BotMessage
              :bots-name="msg.type !== 'user' ? botsName : 'You'"
              :message="msg.message"
              :type="msg.type"
            ></BotMessage>
          </div>
        </div>
      </div>

      <div class="bottom-controls d-flex p-2 mb-0 mt-auto">
        <b-form-input
          ref="chatInput"
          v-model="typing"
          placeholder="Type your question here..."
          :disabled="loading"
          autocomplete="off"
          @keyup.enter="sendMessage"
        />

        <div
          class="chat-box-send-btn d-flex align-items-center justify-content-center"
          @click="sendMessage"
        >
          <ChatSendButton></ChatSendButton>
        </div>
      </div>
    </div>
  </div>

  <div v-else>
    <div class="chat-box chat-box--external d-flex flex-column">
      <div ref="chat_box_content" class="chat-box-content--external overflow-auto p-2 flex-grow-1">
        <!-- Welcome Message  -->
        <span class="welcome-message d-flex flex-row">
          <BotMessage :message="welcomeMessage" type="welcome"> </BotMessage>
        </span>

        <!-- Default Question Options -->
        <div v-if="defaultQuestions.length > 0">
          <div class="mt-1 ml-3" v-for="(defaultQuestion, index) in defaultQuestions" :key="index">
            <button
              class="default-question p-1 px-3"
              @click="applyDefaultQuestion(defaultQuestion)"
            >
              {{ defaultQuestion }}
            </button>
          </div>
        </div>

        <!-- Messages  -->
        <div class="chat-box-messages d-flex flex-column">
          <div v-for="msg in messages" :key="msg.id" class="chat-box-message">
            <BotMessage
              :bots-name="msg.type !== 'user' ? botsName : 'You'"
              :message="msg.message"
              :type="msg.type"
            ></BotMessage>
          </div>
        </div>
      </div>

      <!-- Bottom input -->
      <div class="bottom-controls d-flex mb-0 mt-auto">
        <b-form-input
          ref="chatInput"
          v-model="typing"
          placeholder="Type your question here..."
          :disabled="loading"
          autocomplete="off"
          @keyup.enter="sendMessage"
        />

        <div
          class="chat-box-send-btn d-flex align-items-center justify-content-center"
          @click="sendMessage"
        >
          <b-icon-arrow-up></b-icon-arrow-up>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import AirOps from '@airops/airops-js'
import BotMessage from './BotMessage.vue'
import ChatSendButton from '@app/graphics/ChatSendButton.vue'
import appsignal from '@lib/appsignal'

export default {
  components: {
    BotMessage,
    ChatSendButton,
  },
  props: {
    account: {
      type: Object,
      required: true,
    },
    time: {
      type: String,
      default: new Date().toISOString().split('T')[0],
    },
    // This is a prop that is used to determine if the chatbox is running
    // from within the app, or in the public ue research library
    mode: {
      type: String,
      default: 'internal',
    },
    welcomeMessage: {
      type: String,
      required: false,
    },
    defaultQuestions: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      airops: null,
      loading: false,
      messages: [], // { message: string, type: 'user' | 'bot''}
      typing: null, // current typing message
      streamingResponseMessage: {
        message: '',
        type: 'bot',
      },
      botThinkingDelay: 500,
      sessionId: null,
    }
  },
  computed: {
    botsName() {
      return this.mode == 'internal' ? `${this.account?.name} Testimonial Bot` : null
    },
  },
  beforeMount() {
    this.airops = new AirOps()
  },
  methods: {
    applyDefaultQuestion(questionText) {
      this.typing = questionText
      this.sendMessage()
    },
    handleStream(data) {
      const newToken = data.token
      if (this.isFirstMeaningfulToken(newToken)) {
        this.startResponseMode()
      }
      this.continueResponse(newToken)
    },
    isFirstMeaningfulToken(newToken) {
      const lastMessage = this.messages[this.messages.length - 1]
      // Responses tend to start with a bunch of empty string tokens, so ignore these
      return lastMessage.type === 'loader' && newToken
    },
    startResponseMode() {
      this.messages.pop() // remove the loader message
      this.messages.push(this.streamingResponseMessage)
    },
    continueResponse(newToken) {
      if (newToken) {
        this.streamingResponseMessage.message += newToken
        this.scrollToBottomOfMessages()
      }
    },
    resetStreamingResponseMessage() {
      this.typing = ''
      this.streamingResponseMessage = {
        message: '',
        type: 'bot',
      }
    },
    handleStreamCompleted() {
      this.stopLoadingMode()
      // There are cases where AirOps completes without streaming a single token
      if (this.streamingResponseMessage.message === '') {
        this.handleAirOpsError(new Error('No response from AirOps'))
      } else {
        this.$emit('stream-completed', this.streamingResponseMessage.message)
      }
      this.resetStreamingResponseMessage()
    },
    addUserMessage() {
      this.messages.push({ message: this.typing, type: 'user' })
      this.$refs.chatInput.$el.value = ''
      this.scrollToBottomOfMessages()
    },
    async sendAirOpsRequest() {
      this.startLoadingMode()
      try {
        const response = await this.airops.apps.chatStream({
          appId: process.env.VUE_APP_AIROPS_APP_ID,
          message: this.typing,
          stream: true,
          streamCallback: this.handleStream,
          streamCompletedCallback: this.handleStreamCompleted,
          inputs: {
            account_name: this.account.name,
            account_id: this.account.id,
          },
          ...(this.sessionId && { sessionId: this.sessionId }),
        })
        this.sessionId = response.sessionId
      } catch (e) {
        this.stopLoadingMode()
        this.handleAirOpsError(e)
        this.resetStreamingResponseMessage()
      }
    },
    handleAirOpsError(e) {
      this.messages.push({
        message: 'Sorry, I am not able to answer that question at the moment.',
        type: 'error',
      })
      this.scrollToBottomOfMessages()
      appsignal.sendError(e, (span) => {
        span.setAction('SendMessage')
        span.setNamespace('testimonial_chat')
        span.setParams({ account_id: this.account.id, session_id: this.sessionId })
      })
    },
    stopLoadingMode() {
      this.loading = false
      if (this.messages[this.messages.length - 1].type === 'loader') {
        this.messages.pop()
      }
      this.scrollToBottomOfMessages()
    },
    startLoadingMode() {
      this.loading = true
      this.messages.push({ type: 'loader' })
      this.scrollToBottomOfMessages()
    },
    sendMessage() {
      this.addUserMessage()
      setTimeout(() => {
        this.sendAirOpsRequest()
      }, this.botThinkingDelay)
    },
    scrollToBottomOfMessages() {
      this.$nextTick(() => {
        const chatBoxContent = this.$refs.chat_box_content
        chatBoxContent.scrollTop = chatBoxContent.scrollHeight
      })
    },
  },
}
</script>

<style lang="sass" scoped>
.chat-box
  width: 420px
  height: 450px
  border: 1px solid $border-color
  background-color: white
  border-radius: 8px

  &--external
    height: 647px
    width: 100%
    border: none

.chat-box-content--external
  -ms-overflow-style: none
  scrollbar-width: none

.chat-box-content--external::-webkit-scrollbar
  display: none

.top-controls
  border-top-left-radius: 6px
  border-top-right-radius: 6px
  background-color: $techDebtPurple6
  color: white

.bottom-controls
  padding: 0.8rem
  border-top: 1px solid $border-color
  .chat-box-send-btn
    background: #850AFF
    border-radius: 6px
    min-width: 50px
    margin-left: 0.5rem
    cursor: pointer
    svg
      color: white
  input
    font-size: 0.8rem
    &:focus, &:hover
      box-shadow: none
      outline-style: hidden
      outline-style: none
  input::placeholder
    font-size: 0.8rem

.chat-box-time
  font-size: 10px

.default-question
  background: transparent
  border: 1px solid #e0e0e0
  font-size: 13px
  font-weight: $btn-font-weight
  margin-top: 0.8rem
  border-radius: 16px

.welcome-message
  font-size: 0.8rem
  font-weight: 500

.welcome-message-icon
  color: $uePurple
</style>
